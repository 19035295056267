<template>
  <div class="property-form">
    <v-form ref="form" class="property-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <label class="text-field-label">Property Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Property Name"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Address</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Address"
            v-model="form.address"
            :error-messages="form.$getError('address')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <v-checkbox
            v-model="form.is_active"
            hide-details="auto"
            label="Set as active"
            class="mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Property Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectBuilding from '@/components/fields/SelectBuilding'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectBuilding,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    property: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        name: '',
        address: '',
        building_id: '',
        is_active: false,
      }),
    }
  },

  computed: {
    ...mapState({
      createdProperty: (state) => state.property.propertyDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Property Information' : 'Create Property'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateProperty : this.createProperty
    },

    isUpdate() {
      return !!this.property || !!this.createdProperty
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.PROPERTY_UPDATE], this.permissions)
    },
  },

  watch: {
    property() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createProperty: 'property/createProperty',
      updateProperty: 'property/updateProperty',
    }),

    initForm() {
      if (this.property) {
        this.form.name = this.property.name
        this.form.address = this.property.address
        this.form.building_id = this.property.building_id
        this.form.is_active = this.property.status === 'active'
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Property details successfully updated!')
          } else {
            this.showSnackbar('Property successfully created!')
            this.$router.push({
              name: 'properties',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      form.status = form.is_active ? 'active' : 'inactive'
      delete form.is_active

      if (this.isUpdate) {
        form.id = this.property.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          formData.append(field, form[field])
        }

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.property-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
